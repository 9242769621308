import React, { useMemo } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SectionComponentProps } from './type'
import { groupBy, truncate } from 'lodash'
import { formatDate } from '../../../utils/formatDate'
import { AIInsightData } from '../../../@types/insights'
import { generatePath, Link } from 'react-router-dom'
import { PATH_INITIATIVES } from '../../../routes/paths'
import { responsiveFontSizes } from '../../../utils/formatFontSize'

interface AIInsightsSectionProps extends Omit<SectionComponentProps, 'data'> {
    data: AIInsightData[]
    title?: string
}

interface AIInsightsSectionItemProps extends Omit<AIInsightsSectionProps, 'data'> {
    data: AIInsightData
}

const SplitIntoWords = /([a-z])([A-Z])/g

const AIInsightsSectionItem: React.FC<AIInsightsSectionItemProps> = ({ data, expanded, onChange }) => {
    const { LastModifiedAt, InitiativeLink, Description, InitiativeId, ...details } = data.Details

    const renderValue = (key: any, value: any) => {
        if (['LastModifiedAt', 'From', 'To'].includes(key)) {
            return formatDate(value)
        }
        return value
    }

    return (
        <Accordion
            expanded={expanded}
            onChange={onChange}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={'subtitle1'}>{data.InsightTitle || data['Insight Title']}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    sx={{
                        ...responsiveFontSizes({ sm: 9, md: 11, lg: 13 }),
                    }}
                >
                    {Description}
                    <ul
                        className={'word-wrap'}
                        style={{ listStyle: 'none' }}
                    >
                        {Object.entries(details).map(([key, value]) => {
                            if (key === 'InitiativeName' && InitiativeLink) {
                                const initiativeId = InitiativeLink.includes('/') ? InitiativeLink.split('/').pop() : InitiativeLink
                                return (
                                    <Link
                                        className={'word-wrap'}
                                        key={key + value}
                                        style={{ color: '#1b67c9' }}
                                        target={'_blank'}
                                        to={generatePath(PATH_INITIATIVES.actions.addEdit, { initiativeId: initiativeId })}
                                    >
                                        {value}
                                    </Link>
                                )
                            }
                            return (
                                <li
                                    className={'word-wrap'}
                                    key={key + value}
                                >
                                    <b>{key.replace(SplitIntoWords, '$1 $2')}</b>:{renderValue(key, value)}
                                </li>
                            )
                        })}
                    </ul>
                    <br />
                    Recommendation: {data.Recommendation}
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

const AIInsightsSection: React.FC<AIInsightsSectionProps> = ({ data, weeks, expanded, onChange, title = 'Assistant' }) => {
    const [page, setPage] = React.useState<Record<string, boolean>>({})

    const groupedData = useMemo(() => groupBy(data, 'Insight Title'), [data])
    console.log('data', data)

    if (data.length === 0) {
        return <></>
    }

    return (
        <Accordion
            expanded={expanded}
            onChange={onChange}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={'subtitle1'}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {Object.entries(groupedData).map(([title, items], index) => {
                    console.log('items', items)
                    if (items.length > 1) {
                        return (
                            <AIInsightsSection
                                data={items.map((i, index2) => ({
                                    ...i,
                                    'Insight Title': (truncate(i.Details.InitiativeName || '', { length: 50 }) || '') + Array(index2).fill(' '),
                                }))}
                                title={title}
                                key={`inner-ai-${index}-${title}`}
                                expanded={page[`inner-ai-${index}-${title}`] === true}
                                onChange={() => setPage({ ...page, [`inner-ai-${index}-${title}`]: !page[`inner-ai-${index}-${title}`] })}
                                weeks={weeks}
                            />
                        )
                    }
                    return (
                        <AIInsightsSectionItem
                            key={'AIInsightsSectionItem' + index}
                            data={items[0]}
                            weeks={weeks}
                            expanded={page[`ai-${index}`] === true}
                            onChange={() => setPage({ ...page, [`ai-${index}`]: !page[`ai-${index}`] })}
                        />
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

export default AIInsightsSection
